import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Post, PostNode } from './Post';
import { FadeInContainer } from './FadeInContainer';

type Props = {
    posts: PostNode[]
};

const Container = styled.ul`
    padding: 0 1.5rem;
    list-style: none;
`;
const Item = styled.li`
    max-width: 900px;
    margin: auto;
    transition: .08s all linear;

    :hover {
        background-color: #f4f4f4;
        transition: .14s all linear;
    }
`;

export const Posts: FunctionComponent<Props> = ({ posts }) => {
    const [state, setState] = useState('hidden');

    useEffect(() => { setTimeout(() => setState('visible'), 100) }, []);

    return (
        <FadeInContainer className={state}>
            <Container>
                {posts.map(post => (
                    <Item id={post.id} key={post.id}>
                        <Post post={post} to={`/posts/${post.id}`} />
                    </Item>
                ))}
            </Container>
        </FadeInContainer>
    );
}
