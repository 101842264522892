import React, { FunctionComponent, useMemo } from 'react';
import styled from '@emotion/styled';
import { Link, GatsbyLinkProps } from 'gatsby';

type Props = {
    primary?: boolean
    outlined?: boolean
    to?: string
    className?: string
}

export const Badge: FunctionComponent<Props> = props => (
    <BadgeContainer className={props.className} primary={!!props.primary} outlined={!!props.outlined} to={props.to}>
        {!!props.to
            ? <Link to={props.to} className="content">{props.children}</Link>
            : <div className="content">{props.children}</div>
        }
    </BadgeContainer>
)
    
const BadgeContainer = styled.div<Props>`
    display: inline-block;
    .content {
        border-width: 1px;
        border-style: solid;
        border-color: ${props => !props.primary ? 'var(--color-gray-lighter)' : 'var(--color-primary)' };
        border-radius: 3px;
        box-sizing: border-box;
        background-color: ${({ outlined }) => outlined ? 'transparent' : 'var(--color-primary)' };
        color: ${({ outlined }) => outlined ? 'var(--color-black)' : 'white' };
        display: inline-block;
        font-size: 0.8rem;
        padding: 2px 0.5rem;
        text-decoration: none;
        cursor: ${({ to }) => !!to ? 'pointer' : 'unset' }
    }
`;
