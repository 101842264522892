import React, { ComponentProps, FunctionComponent } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

type Props = ComponentProps<'header'> & { title: string };

const StyledHeader = styled.header`
    position: sticky;
    top: 0;
    background-color: var(--color-primary);
    color: white;
`;

const HeaderTitle = styled.div`
    cursor: pointer;
    font-size: 1.5rem;

    a {
        padding: 1.2rem 1.5rem;
        display: inline-block;
        text-decoration: none;
        color: currentColor;
    }
`;

export const Header: FunctionComponent<Props> = ({ title }) => (
    <StyledHeader>
        <HeaderTitle>
            <Link to="/">{title}</Link>
        </HeaderTitle>
    </StyledHeader>
);
