import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

type Props = {
    prev?: null | string,
    next?: null | string
}

const Container = styled.nav`
    display: flex;
    flex-direction: row;
    padding: 1.5rem 1rem;
    max-width: 900px;
    margin: auto;

    .prev,
    .next {
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: var(--color-gray-lighter);
        padding: 0.4rem 1rem;
        border-radius: 3px;
        text-decoration: none;
        color: var(--color-gray-darker);
        cursor: pointer;
        transition: .1s all linear;

        &:hover {
            transition: .14s all linear;
            border-color: var(--color-primary);
            background-color: var(--color-primary);
            color: white;
        }
    }
    .next {
        margin-left: auto;
    }
`;

export const Pagination: FunctionComponent<Props> = ({ prev, next }) => (
    <Container>
        { prev ? <Link className="prev" to={prev}>&lt; prev</Link> : null }
        { next ? <Link className="next" to={next}>next &gt;</Link> : null }
    </Container>
);
