import React, { ComponentProps, FunctionComponent } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';

import './styles.css';
import { Header } from './Header';
import { Footer } from './Footer';
import { Profile } from './Profile';

type Props = ComponentProps<'div'> & {
    name?: string,
    title?: string,
    description?: string
    type?: 'website' | 'article'
    url: string,
    image: string,
};

const defaultProps = {
    name: 'ponday.com',
    description: 'フロントエンド好きのらっこの雑記',
    type: 'website'
};

const Container = styled.div`
`;

const Main = styled.main`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

export const Layout: FunctionComponent<Props> = props => {
    const { children, type, name, title, description, url, image } = { ...defaultProps, ...props }
    const pageTitle = `${!!title ? `${title} - ` : ''}${name}`
    return (
        <Container>
            <Helmet>
                <html lang="ja" />
                <title>{ pageTitle }</title>
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content={ type } />
                <meta property="og:site_name" content={name} />
                <meta property="og:image" content={image} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@ponday_dev" />
            </Helmet>
            <Header title="ponday.com"/>
            <Main>
                <div style={{ flex: '1 1 auto' }}>
                { children }
                </div>
                <div style={{ backgroundColor: '#f0f0f0' }}>
                    <Profile/>
                </div>
            </Main>
            <Footer />
        </Container>
    );
}
