import styled from '@emotion/styled';

export const FadeInContainer = styled.div`
    padding: 2rem 1rem;
    opacity: 0;

    &.visible {
        opacity: 1;
        transition: .2s all linear;
    }
`;
