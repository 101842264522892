import React, { FunctionComponent, ComponentProps } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { Badge } from './Badge';
import { RemarkNode } from '../models/remark-node';

export type PostNode = Pick<RemarkNode, Exclude<keyof RemarkNode, 'html'>>;

type Props = ComponentProps<typeof Link> & {
    post: PostNode,
};

const PostItem = styled(Link)`
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: 1.25rem 0.5rem 0.8rem 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .title {
        color: var(--color-black);
        font-size: 1.4rem;
        margin: 0 0 0.5rem 0;
    }
    .sub-container {
        margin: 0 0 1rem 0;
    }
    .example {
        font-size: 1rem;
        color: var(--color-gray);
        overflow-wrap: break-word;
    }
    .publish-date {
        font-size: 0.9rem;
        color: var(--color-gray-darker);
        font-weight: bold;
        margin-left: 0.5rem;
    }
    .tags {
        margin: 1rem 0 0 0;
    }
    .tag {
        margin-right: 0.5rem;
    }
`;

export const Post: FunctionComponent<Props> = ({ post, to }) => (
    <PostItem to={to} >
        <h3 className="title">{post.frontmatter.title}</h3>
        <section className="sub-container">
            <Badge primary>{post.frontmatter.category}</Badge>
            <span className="publish-date">{post.frontmatter.date}</span>
        </section>
        <section className="example">{post.excerpt}</section>
        <section className="tags">
            {(post.frontmatter.tags || []).map(tag => {
                return (
                    <Badge className="tag" key={tag} outlined>#{tag}</Badge>
                );
            })}
        </section>
    </PostItem>
);

