import React from 'react';
import styled from '@emotion/styled';

const Base = styled.footer`
    background-color: var(--color-primary);
    color: white;
    font-size: 12px;
    padding: 0.8rem 1.6rem;
`;

export const Footer = () => (
    <Base>
        Copyright © 2019 @ponday All Rights Reserved.
    </Base>
);
