import React from 'react';
import styled from '@emotion/styled';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitterSquare, faTwitter, faGithubSquare, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faTwitter, faTwitterSquare, faGithubSquare, faFacebookF);

const Container = styled.aside`
    padding: 1.2rem 1rem;
    width: 300px;

    h2 {
        margin: 0 0 .5rem 0;
        color: var(--color-primary);
        font-size: 1.1rem;
    }
`;

const Avatar = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
`;

const ProfileSection = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;

    .name {
        font-size: 1.1rem;
        display: block;
        color: var(--color-primary);
        font-weight: bold;
    }
    .role {
        font-size: 0.9rem;
    }
`;

const Description = styled.section`
    margin-top: 1rem;
    font-size: 0.9rem;
    overflow-wrap: break-word;

    p {
        margin: 0 0 .5rem 0;
    }
`;

const SocialLinks = styled.section`
    font-size: 0.8rem;
    margin-top: 0.8rem;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        display: inline-block;
        margin-right: 1rem;
    }

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.2rem 0;
        text-decoration: none;
        color: currentColor;

        svg[data-icon="twitter-square"] {
            color: #55acee;
        }
        svg[data-icon="github-square"] {
            color: #333;
        }
        
        .sns {
            margin-left: 0.4rem;
        }
    }
`;

export const Profile = () => (
    <Container>
        <h2>Profile</h2>
        <ProfileSection>
            <Avatar src="/assets/icon.png" alt="アバター" />
            <p style={{ margin: '0 0 0 1rem' }}>
                <span className="name">ponday</span>
                <span className="role">Engineer</span>
            </p>
        </ProfileSection>
        <Description>
            <p>
                フロントエンドもバックエンドもまんべんなく。
                TypeScriptが好き。
            </p>
        </Description>
        <SocialLinks>
            <ul>
                <li>
                    <a href="https://twitter.com/ponday_dev" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['fab', 'twitter-square']} size="2x" />
                        <span className="sns">Twitter</span>
                    </a>
                </li>
                <li>
                    <a href="https://github.com/ponday-dev" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['fab', 'github-square']} size="2x"/>
                        <span className="sns">GitHub</span>
                    </a>
                </li>
            </ul>
        </SocialLinks>
    </Container>
);
